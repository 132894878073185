
const initialState = {
    isLoading: false,
    open: false,
    token : ''
};

export const loginReducer = (state = initialState, action) => {
    return state;
};
