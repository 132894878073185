import React from 'react';
import Header from "../Header";
import Footer from "../Footer";

const ReceiptOfReports = () => {
    return (
        <div>
            <Header/>
            <div className="container my-5">
               <div className="row">
                   <p className="main_text">Фойдали қазилмалар захиралари ҳаракати тўғрисида ҳисоботларни
                       қабул қилиш (5-ГР шакли)
                   </p>
                   <a href="/images/5gr.jpg" className="beshgrYuklash" target="_blank">5-ГР шаклини юклаш</a>
               </div>


                {/*<div className="row">*/}
                {/*    <div className="col-6 pl-0">*/}
                {/*        <p className="assistant_text">*/}
                {/*            Ер қаъридан фойдаланувчиларнинг фойдали қазилмаларни қазиб олиш тўғрисидаги ҳисоботларини*/}
                {/*            тақдим*/}
                {/*            этиш тартиби "Ўзбекистон Республикаси фойдали қазилмалари захираларининг давлат балансини*/}
                {/*            юритиш*/}
                {/*            тартиби тўғрисидаги низомни тасдиқлаш ҳақида" билан тартибга солинади (Ўзбекистон*/}
                {/*            Республикаси*/}
                {/*            Вазирлар Маҳкамасининг 2014 йил 29 июлдаги 206-сонли қарорига 1-илова).*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Ер қаъридан фойдаланувчилар томонидан ҳар йили 1 февралга қадар чегириладиган захиралар*/}
                {/*            (амалга*/}
                {/*            оширилган қазиб чиқаришлар ва йўқотишлар) ҳажми, қазиб чиқариш жараёнида исботланмаган ёки*/}
                {/*            қўшимча равишда кўпайтириш ҳисобига техник чегараларнинг ўзгариши, захираларни ҳисобдан*/}
                {/*            чиқариш,*/}
                {/*            Ўзбекистон Республикаси Давлат геология ва минерал ресурслар қўмитаси ҳузуридаги Фойдали*/}
                {/*            қазилмалар захиралари бўйича Давлат комиссияси (кейинги ўринларда Давлат комиссияси деб*/}
                {/*            юритилади) қарорлари, шунингдек идоравий комиссияларнинг тезкор ҳисоблаб чиқилган*/}
                {/*            захираларни*/}
                {/*            синовдан ўтказиш бўйича баённомалари асосида ўтган йил учун фойдали қазилмалар*/}
                {/*            захираларининг*/}
                {/*            ҳисобот баланслари (кейинги ўринларда ҳисобот баланслари деб юритилади) тузилади.*/}
                {/*        </p>*/}
                {/*        <p style={{color: "#000000", fontWeight: "600"}}>*/}
                {/*            Ҳисобот баланслари:*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            қаттиқ фойдали қазилмалар;*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            ер ости сувлари;*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            кўмир ва ёнувчи сланецлар;*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            нефть, конденсат, ёнувчи газлар (жумладан йўлдош компонентлар — этан, пропан, бутан,*/}
                {/*            олтингугурт,*/}
                {/*            гелий) бўйича алоҳида тузилади.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Ҳисобот балансларига қуйидагилар акс эттириладиган тушунтириш хати илова қилинади:*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            биринчи марта давлат балансига киритилаётган конлар учун — фойдали қазилмалар захираларининг*/}
                {/*            жойлашган жойи, миқдори, сифати, ўрганилганлик ва саноат йўсинида ўзлаштирилиши даражаси,*/}
                {/*            рудаларнинг технологик хусусиятлари, конларда қазиб олишнинг кончилик-геология,*/}
                {/*            кончилик-техникавий,*/}
                {/*            гидрогеологик шарт-шароитлари, техник-иқтисодий баҳолашнинг асосий кўрсаткичлари, қидириб*/}
                {/*            топилган*/}
                {/*            фойдали қазилмалар захиралари билан таъминланганлиги;*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            давлат балансида ҳисобга олинган конлар учун — кончилик-геология ва техник-иқтисодий*/}
                {/*            хусусиятининг*/}
                {/*            янги маълумотлари, фойдали қазилмаларнинг янги аниқланган қатламлари, қазиб чиқарилган*/}
                {/*            фойдали*/}
                {/*            қазилмалар миқдори тўғрисидаги маълумотлар, минерал хом ашёдан мажмуавий фойдаланиш,*/}
                {/*            геология-қидирув ишларининг молиялаштириш манбаларини кўрсатган ҳолда фойдали қазилмалар*/}
                {/*            захираларининг кўпайтирилиши тўғрисидаги маълумотлар, шунингдек балансдан ташқари*/}
                {/*            захираларнинг*/}
                {/*            ўзгариши (мавжуд бўлганда) ва захираларни ҳисобдан чиқариш сабаблари.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Фойдали қазилмани қазиб чиқариш тўғрисидаги маълумотларда фойдали қазилмани камайтирадиган*/}
                {/*            бўш*/}
                {/*            жинсларини ҳисобга олмаган ҳолда, агар улар балансдаги захираларига киритилмаган бўлса, ер*/}
                {/*            қаъридан*/}
                {/*            олинадиган минерал хом ашёнинг барча миқдори ҳисобга олиниши керак. Ҳисобот балансида қазиб*/}
                {/*            чиқариб*/}
                {/*            камайтирилган ва товар рудалари миқдори тўғрисидаги маълумотлар акс эттирилмайди, бироқ*/}
                {/*            ҳисобот*/}
                {/*            балансига қўшиладиган тушунтириш хатида келтирилади.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Қандайдир сабабларга кўра, ўтган йиллар учун илгари ҳисобга олинмаган фойдали қазилмани*/}
                {/*            қазиб*/}
                {/*            чиқариш ҳисобот йили учун қазиб чиқариш билан бирга кўрсатилади, бироқ ўтган йилда қазиб*/}
                {/*            олинган хом*/}
                {/*            ашё изоҳда кўрсатилади.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Агар давлат балансида ҳисобга олинган комплекс рудалардаги йўлдош компонентлар ер қаъридан*/}
                {/*            асосий*/}
                {/*            фойдали қазилма билан бирга қазиб чиқарилса, бироқ кейинги технологик қайта ишлашда*/}
                {/*            йўқотилса,*/}
                {/*            изоҳда ҳисобдан чиқариладиган захиралар қазиб олинган, лекин технологик қайта ишлашда*/}
                {/*            йўқотилганлигини кўрсатиб ўтиш зарур.*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*    <div className="col-6">*/}
                {/*        <p style={{color: "#000000", fontWeight: "600"}}>*/}
                {/*            Қуйидаги ҳолатларда, агар:*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            фойдали қазилмаларни қазиб чиқариш балансдан ташқари захиралардан амалга оширилса ва қазиб*/}
                {/*            чиқарилган хом ашё миқдори саноатда фойдаланилса, унда қазиб олинган хом ашё миқдори қайта*/}
                {/*            баҳолашдан ўтган сифатида ҳисобга олинади ҳамда фойдали қазилмаларнинг балансдан ташқари*/}
                {/*            захиралари ушбу миқдорга камайтирилади;*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            балансдан ташқари фойдали қазилмалар балансдаги фойдали қазилмалар билан бирга қазиб*/}
                {/*            чиқарилса, бироқ саноатда фойдаланилмаса ёки кондиционли хом ашё билан бирга камайтирадиган*/}
                {/*            жинс сифатида қайта ишлаб чиқаришга тушган бўлса, фойдали қазилманинг балансдан ташқари*/}
                {/*            захиралари ҳисобот йилида қазиб чиқарилган сифатида ҳисобот балансида камайтирилади.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Ҳисобот баланслари шакллари Давлат геология қўмитаси қарорига кўра, фойдали қазилмалар*/}
                {/*            турлари параметрларининг ўзига хос хусусиятларини ҳисобга олган ҳолда тасдиқланади. Ер*/}
                {/*            қаъридан фойдаланувчилар ўз ҳисоботларини Давлат геология қўмитаси томонидан белгиланган*/}
                {/*            шакллар бўйича расмийлаштириши шарт.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Ҳисобот баланси юридик шахс — ер қаъридан фойдаланувчи раҳбари ва бош геологи (бош*/}
                {/*            муҳандиси) томонидан имзоланади. Агар ер қаъридан фойдаланувчи жисмоний шахс ҳисобланса,*/}
                {/*            ягона шахснинг имзосига рухсат берилади.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Қазиб олинадиган конлар бўйича ҳисобот баланслари геодезия ва картография фаолиятини амалга*/}
                {/*            ошириш ҳуқуқига лицензияси мавжуд бўлганда ер қаъридан фойдаланувчиларнинг*/}
                {/*            геология-маркшейдерлик хизматлари томонидан тасдиқланиши керак ҳамда Ўзбекистон Республикаси*/}
                {/*            Давлат геология ва минерал ресурслар қўмитаси ҳузуридаги Кон-геология фаолиятини назорат*/}
                {/*            қилиш инспекцияси (кейинги ўринларда Инспекция деб юритилади) билан келишилади.*/}
                {/*            Углеводородлар конлари ва ер ости сувлари бўйича ҳисобот балансларини ер қаъридан*/}
                {/*            фойдаланувчиларнинг тегишли функциялар юкланган бошқа хизматлари томонидан тасдиқлашга*/}
                {/*            рухсат берилади.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Ер қаъридан фойдаланувчиларда геодезия ва картография фаолиятини амалга ошириш ҳуқуқига*/}
                {/*            лицензияси мавжуд бўлмаганда, ҳисобот балансларини тегишли лицензияга эга бўлган жалб*/}
                {/*            этилган корхоналар томонидан тасдиқланишига рухсат берилади.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Ер қаъри участкаларини геологик ўрганиш ва фойдали қазилмаларни тажриба-саноат йўсинида*/}
                {/*            қазиб чиқариш натижалари бўйича ҳисоботлар Давлат комиссиясининг фойдали қазилмалар*/}
                {/*            захираларини тасдиқлаш (ёки қайта тасдиқлаш) бўйича тегишли қарорлари нусхалари ва идоравий*/}
                {/*            комиссияларнинг тезкор ҳисоблаб чиқилган захираларни синовдан ўтказиш бўйича баённомалари*/}
                {/*            билан биргаликда тақдим этилади.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Шунингдек, агар кейинчалик қазиб олишда исботланмаганлиги, техник чегараларнинг ўзгарганлиги*/}
                {/*            ва бошқа сабаблар билан боғлиқ ҳолда фойдали қазилмалар захиралари конлар бўйича қайта*/}
                {/*            ҳисоблаб чиқилган бўлса, ҳисоботлар Давлат комиссиясининг тегишли қарорлари нусхалари билан*/}
                {/*            биргаликда тақдим этилади.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Ҳисобот баланслари уч нусхада тузилади ва ер қаъридан фойдаланувчилар томонидан ҳар йили 5*/}
                {/*            февралдан кечикмасдан Давлат геология қўмитаси тизимида ўз фаолиятини амалга оширувчи Давлат*/}
                {/*            геология фондига юбориш:*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            норуда фойдали қазилмалар бўйича — Инспекциянинг ҳудудий бўлинмалари;*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            рудали фойдали қазилмалар ва углеводородлар бўйича Инспекция томонидан амалга оширилади.*/}
                {/*        </p>*/}
                {/*        <p className="assistant_text">*/}
                {/*            Ҳисобот баланслари Давлат геология фондига қабул қилингандан кейин бир нусхаси тегишли белги*/}
                {/*            билан ер қаъридан фойдаланувчига қайтарилади. Иккинчи нусхаси Инспекцияга тақдим қилиниши*/}
                {/*            керак.*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <Footer/>
        </div>
    );
};

export default ReceiptOfReports;
