import React, {useEffect, useState} from 'react';
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import {API_PATH} from "../../tools/constants";

const ReportingSpecialists = () => {

    const [xabar , setXabar] = useState([]);
    useEffect(()=>{
        axios.get(API_PATH + "Mutaxassislarning-aloqasi/")
            .then((res)=>{
                setXabar(res.data.results);
            })
    },[]);

    return (
        <div>
            <Header/>

            <div className="container my-5">
               <div className="row">
                   <p className="main_text">
                       Фойдали қазилмалар захиралари ҳаракати тўғрисида ҳисобот олиш
                       учун масъул мутахассисларнинг алоқалари (5-ГР шакли)
                   </p>
               </div>
                <div className="row mt-3">
                    {xabar.map((item,index)=>{
                        return(
                            <div className="col-6 pl-0 mt-2 assistant_text">
                                {item.text}
                                {/*"Ўзбекистон Республикасининг фойдали қазилмалар захираларининг давлат балансини юритиш тартиби*/}
                                {/*тўғрисидаг Низом" га мувофиқ, фойдали қазилмалар захиралари ва таркибидаги таркибий қисмларнинг*/}
                                {/*ҳолати ва ҳаракатини ҳисобга олиш устидан назоратни Кон-геология фаолиятини назорат қилиш*/}
                                {/*инспекцияси амалга оширади.*/}
                            </div>
                        )
                    })}
                    {/*<div className="col-6 assistant_text">*/}
                    {/*    Инспекция, шунингдек, фойдаланувчи ҳисобидан ўчириш учун тақдим етилган маълумотлар, шунингдек*/}
                    {/*    фойдали қазилмалар захираларини қазиб олиш жараёнида қазиб олинадиган ва йўқотилган ер ости*/}
                    {/*    бойликлари, шунингдек, саноат қийматини йўқотган ёки кейинги разведка давомида тасдиқланмаган*/}
                    {/*    фойдали қазилмалар захиралари ҳамда давлат ҳисоботини тақдим етишнинг белгиланган тартибига риоя*/}
                    {/*    қилинганлигини назорат қилади. , шунингдек, геологик маълумот фондларидаги ер ости бойликлари*/}
                    {/*    тўғрисидаги геологик ва бошқа маълумотлар (Ер ости бойликларини қазиб олиш жараёнида қазиб*/}
                    {/*    олинган ва йўқотилган фойдали қазилмалар захираларини ер қаъридан фойдаланувчининг ҳисобидан*/}
                    {/*    чиқариш тартиби тўғрисида, Ўзбекистон Республикаси Вазирлар Маҳкамасининг 04.12.2018 йилдаги*/}
                    {/*    983-сонли қарорига 3-илова).*/}
                    {/*</div>*/}
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default ReportingSpecialists;
